<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      fixed-header
      height="auto"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
          >
            <v-text-field
              dense
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-tooltip top v-if="isDetail">
            <template v-slot:activator="{ on, attrs }">
              <span
                class="hand mr-2"
                @click="detail(item)"
                v-bind="attrs"
                v-on="on"
              >
                <i class="feather icon-eye"></i>
              </span>
            </template>
            <span>{{ $t("table.detail") }}</span>
          </v-tooltip>

          <v-tooltip top v-if="isButtonPDF">
            <template v-slot:activator="{ on, attrs }">
              <span
                class="hand text-fmedical-red"
                @click="downloadPDF(item)"
                v-bind="attrs"
                v-on="on"
              >
                <i class="fa fa-file-pdf-o"></i>
              </span>
            </template>
            <span>{{ $t("table.download_PDF") }}</span>
          </v-tooltip>

          <v-tooltip top v-if="isSend">
            <template v-slot:activator="{ on, attrs }">
              <span
                style="cursor: pointer"
                class="ml-3 icon-gray"
                @click="receiveShipment(item)"
                v-bind="attrs"
                v-on="on"
              >
                <i class="fa fa-arrow-right"></i>
              </span>
            </template>
            <span>{{ $t("table.receive_shipment") }}</span>
          </v-tooltip>
          <div v-if="!isDetail && !isSend && !isButtonPDF">-</div>
        </div>
      </template>
    </v-data-table>

    <ButtonTableComponent v-if="isButton" v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";

export default {
  props: {
    headers: {},
    isDetail: false,
    isSend: false,
    isButtonPDF: false,
    isButton: false,
  },
  data() {
    return {
      search: "",
      sending: false,
      dataList: [],
      permissions: PermissionConstants,
    };
  },
  components: {
    ButtonTableComponent,
  },
  methods: {
    /* General */
    excelTable() {
      this.$emit("excelTable");
    },
    detail(item) {
      this.$emit("detail", item);
    },
    downloadPDF(item) {
      this.$emit("downloadPDF", item);
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    reloadComponent(dataList) {
      this.dataList = dataList;
    },
    receiveShipment(item) {
      this.$emit("receiveShipment", item);
    },
  },
  created() {},
};
</script>
