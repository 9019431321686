var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataList,"search":_vm.search,"fixed-header":"","height":"auto","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',[(_vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.detail(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.detail")))])]):_vm._e(),(_vm.isButtonPDF)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-red",on:{"click":function($event){return _vm.downloadPDF(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"fa fa-file-pdf-o"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.download_PDF")))])]):_vm._e(),(_vm.isSend)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"ml-3 icon-gray",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.receiveShipment(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"fa fa-arrow-right"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.receive_shipment")))])]):_vm._e(),(!_vm.isDetail && !_vm.isSend && !_vm.isButtonPDF)?_c('div',[_vm._v("-")]):_vm._e()],1)]}}],null,true)}),(_vm.isButton)?_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }